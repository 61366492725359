<footer id="about">
    <div class="gap7">
        <img class="footer-img3" src="./../../../assets/images/1_hero/SVG/sakura_logo.svg" alt="pinke Blume">
        <img class="footer-img2" src="./../../../assets/images/5_section_footer/SVG/sakura_ramen_logo_and_text.svg" alt="">
    </div>
    <div class="gap7" id="gaplogo">
        <img class="footer-img" src="./../../../assets/images/5_section_footer/SVG/tripadvisor_logo.svg" alt="">
        <img class="footer-img" src="./../../../assets/images/5_section_footer/SVG/facebook_logo.svg" alt="">
        <img class="footer-img" src="./../../../assets/images/5_section_footer/SVG/instagram_logo.svg" alt="">
    </div>  
</footer>
