<section class="section p1">
    <h2>HOW TO ORDER</h2>
    <div id="flex" class="gap center">
        <div class="order">
                <div><img class="food-logos" src="./../../../assets/images/2_section_how_to_order/SVG/noodle.svg" alt=""></div>
                <div><h3 class="order2">Pick your<br>noodle</h3></div>
        </div>
        <div class="order dist">
                <div><img class="food-logos" src="./../../../assets/images/2_section_how_to_order/SVG/broth.svg" alt=""></div>
                <div><h3 class="order2">Pick your<br>broth</h3></div>  
        </div>
        <div class="order">
                <div><img class="food-logos" src="./../../../assets/images/2_section_how_to_order/SVG/egg.svg" alt=""></div>
                <div><h3 class="order2">Add your<br>toppings</h3></div> 
        </div>
    </div>
</section>
