import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'app-find-us-at',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './find-us-at.component.html',
  styleUrl: './find-us-at.component.sass'
})
export class FindUsAtComponent {

}
