import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FindUsAtComponent } from './find-us-at/find-us-at.component';
import { HowToOrderComponent } from './how-to-order/how-to-order.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { MenuComponent } from './menu/menu.component';

@Component({
  selector: 'app-main-content',
  standalone: true,
  imports: [
    CommonModule,
    FindUsAtComponent,
    HowToOrderComponent,
    LandingPageComponent,
    MenuComponent
  ],
  templateUrl: './main-content.component.html',
  styleUrl: './main-content.component.sass'
})
export class MainContentComponent {

}
