<section class="section p2" id="menu">
        <h2>OUR MENU</h2>
    <div class="gap left">
        <img class="top food-images gap-right" src="./../../../assets/images/3_section_our_menu/Png/R1.png" alt="Tonkotsu Ramen">
        <div class="size2">
            <div >
                <h3 class="gap3">Tonkotsu Ramen</h3>
                <div class="space">
                    <div>
                        <h4>CHICKEN</h4>
                        <h4>BEEF</h4>
                        <h4>SHRIMP</h4>
                    </div>
                    <div>
                        <h4>11.95&euro;</h4>
                        <h4>12.95&euro;</h4>
                        <h4>13.95&euro;</h4>
                    </div>    
                </div>
            </div>
        </div>
    </div>
    <div class="gap2 right">
        <div id="a" class="size2">
            <div id="order">
                <h3 class="gap3">Spicy Miso Ramen</h3>
                <div class="space">
                    <div>
                        <h4>CHICKEN</h4>
                        <h4>BEEF</h4>
                        <h4>SHRIMP</h4>
                    </div>
                    <div>
                        <h4>11.95&euro;</h4>
                        <h4>12.95&euro;</h4>
                        <h4>13.95&euro;</h4>
                    </div>
                </div>
            </div>        
        </div>
        <img id="b" class="middle food-images gap-left" src="./../../../assets/images/3_section_our_menu/Png/R2.png" alt="Spicy Miso Ramen">
    </div>
    <div class="gap2 left">
        <img class="bottom food-images gap-right" src="./../../../assets/images/3_section_our_menu/Png/R3.png" alt="Shio Ramen">
        <div class="size2">
            <div>
                <h3 class="gap3">Shio Ramen</h3>
                <div class="space">
                    <div>
                        <h4>CHICKEN</h4>
                        <h4>BEEF</h4>
                        <h4>SHRIMP</h4>
                    </div>
                    <div>
                        <h4>11.95&euro;</h4>
                        <h4>12.95&euro;</h4>
                        <h4>13.95&euro;</h4>
                    </div>  
                </div>
            </div>      
        </div>
    </div>
</section>
