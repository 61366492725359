<section id="size">
    <div>
        <div><h1>SAKURA RAMEN</h1></div>
        <div><h5 id="heading-h5">THE BEST IN RAMEN IN TOWN</h5></div>
    </div>
    <header>
        <div>
            <img id="Logo" src="./../../../assets/images/1_hero/SVG/sakura_logo.svg" alt="pinke Blume">
        </div>
        <div>
            <a class="Raleway h" href="#menu">MENU</a>
            <a class="Raleway h" href="#locations">LOCATIONS</a>
            <a class="Raleway h" href="imprint">ABOUT</a>
            <a class="Raleway button" id="phonebutton" href="tel:+4917654564534"><img id="phone" src="./../../../assets/images/1_hero/SVG/phone.svg" alt="Telefon Logo"> 0176 54564534</a>
        </div>
    </header>
</section>